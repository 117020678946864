<template>
	<div class="spares-view">
		<div class="wrapper">
			<el-breadcrumb separator="/" class="main-breadcrumb">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>详细参数</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="spares-details-title">
				<h1>{{ ruleForm.name }}</h1>
			</div>
			<div class="content">
				<el-row :gutter="30">
					<el-col :span="7">
						<div class="info">
							<div class="content-title">备品基本信息</div>
							<ul>
								<li><span>备品名称</span><em>{{ruleForm.name}}</em></li>
								<li><span>订货代码</span><em>{{ruleForm.buyCode}}</em></li>
								<li><span>供应商代码</span><em>{{ruleForm.supplier}}</em></li>
								<li class="price">
									<span>指导价</span><em>￥{{common.calcPrice(ruleForm.priceEndUser, ruleForm.priceXichai)}}</em>
								</li>
								<li class="price">
									<span>服务站价</span><em>￥{{ruleForm.priceServiceTax ? ruleForm.priceServiceTax : '-'}}</em>
								</li>
								<li class="price"><span>供货价</span><em>-</em></li>
							</ul>
						</div>
					</el-col>
					<el-col :span="10">
						<el-carousel class="spares-carousel" interval="6000" @change="carouselChange" ref="carousel">
							<el-carousel-item v-for="(item,index) in ruleForm.imgs.filter(e=>e.major==1)" :key="item.id"
								:name="index">
								<el-image fit="contain" :src="item.img" :preview-src-list="srcList"></el-image>
							</el-carousel-item>
							<el-empty v-if="ruleForm.imgs.filter(e=>e.major==1).length==0"
								image="https://yuweiyeke.oss-cn-zhangjiakou.aliyuncs.com/static/images/kkx/20220609/no-img.png"
								description="暂无主图"></el-empty>
						</el-carousel>
					</el-col>
					<el-col :span="7">
						<div class="remark">
							<el-table height="294" :data="adapters" class="main-table">
								<el-table-column prop="strain" label="适配品系" align="center" width="80"></el-table-column>
								<el-table-column prop="name" label="品名"></el-table-column>
							</el-table>
							<p v-if="ruleForm.adapter">{{ruleForm.adapter}}</p>
						</div>
					</el-col>
				</el-row>
				<table border="0" cellspacing='0' class="info-table">
					<thead>
						<tr>
							<th>产品外包装尺寸(cm)</th>
							<th>裸件尺寸(cm)</th>
							<th>产品净重量(kg)</th>
							<th>带包装重量(kg)</th>
							<th>包装定额(件)</th>
							<th>总库存数量(件)</th>
							<th>产品材质</th>
							<th>其他尺寸</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><span>{{ruleForm.outPkgLen?ruleForm.outPkgLen:'无'}}</span> * <span>{{ruleForm.outPkgWidth?ruleForm.outPkgWidth:'无'}}</span> *
								<span>{{ruleForm.outPkgHeight?ruleForm.outPkgHeight:'无'}}</span>
							</td>
							<td><span>{{ruleForm.innerLen?ruleForm.innerLen:'无'}}</span> * <span>{{ruleForm.innerWidth?ruleForm.innerWidth:'无'}}</span> *
								<span>{{ruleForm.innerHeight?ruleForm.innerHeight:'无'}}</span>
							</td>
							<td><span>{{ruleForm.weight?ruleForm.weight:'无'}}</span></td>
							<td><span>{{ruleForm.outWeight?ruleForm.outWeight:'无'}}</span></td>
							<td><span>{{ruleForm.newPackageQuota?ruleForm.newPackageQuota:'无'}}</span></td>
							<td><span>{{ruleForm.qty?ruleForm.qty:'无'}}</span>
								<el-button size="mini" type="primary" class="stock-btn"
									@click="stockDialogVisible = true">
									详情</el-button>
							</td>
							<td><span>{{ruleForm.materialId?getMaterialName(ruleForm.materialId):'无'}}</span></td>
							<td>{{ruleForm.otherSize?ruleForm.otherSize:'无'}}</td>
						</tr>
					</tbody>
				</table>
				<el-dialog custom-class="stock-dialog" :visible.sync="stockDialogVisible" width="50%">
					<div class="stock">
						<div class="content-title">库存数</div>
						<ul>
							<li v-for="item in ruleForm.stores" :key="item.id">
								<span>{{item.storeName.replace('区域库-', '').replace('区域库分库-', '')}}</span><em>{{item.qty}}</em>
							</li>
						</ul>
					</div>
				</el-dialog>
				<el-row :gutter="20">
					<el-col :span="24">
						<div class="term other">
							<div class="content-title2">备品图片</div>
							<div class="term-com other-com">
								<span v-if="ruleForm.imgs.length==0">暂无备品图片</span>
								<el-image fit="contain" v-for="(item,index) in ruleForm.imgs.filter(e=>e.major!=1)"
									:key="index" class="other-img" :src="item.img" :preview-src-list="otherSrcList">
								</el-image>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="20" class="vip-show">
					<el-col :span="24">
						<div class="term">
							<div class="content-title2">备品维修参数</div>
							<div class="term-com">
							</div>
						</div>
						<div class="mask"></div>
						<div class="tips">需要vip及以上会员方能查看</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import http from "../http.js";
	import power from "../power.js";
	import commFunc from "../commFunc.js";
	export default {
		components: {},
		data() {
			return {
				nameList: [],
				otherSrcList: [],
				srcList: [],
				stockDialogVisible: false,
				datuImgUrl: '',
				reasonShow: true,
				xiaotuIndex: '0',
				carouselList: [{
					id: 1,
					img: 'https://img2.baidu.com/it/u=3116699095,2862677591&fm=253&fmt=auto&app=120&f=JPEG?w=890&h=500'
				}, {
					id: 2,
					img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2Fedpic%2F55%2Fbd%2F7b%2F55bd7b04e3de581cd67576c0529e13e8.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1649555935&t=cbde53b3863117db7194f951c7730be6'
				}, {
					id: 3,
					img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fqqpublic.qpic.cn%2Fqq_public%2F0%2F0-2869389320-1784FFBB07CAAF33070364BD81B422BE%2F0%3Ffmt%3Djpg%26amp%3Bsize%3D39%26amp%3Bh%3D613%26amp%3Bw%3D900%26amp%3Bppv%3D1&refer=http%3A%2F%2Fqqpublic.qpic.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1649555917&t=d3c3f39635f8cdf8cfd23cf4ab6a7969'
				}, {
					id: 4,
					img: 'https://img2.baidu.com/it/u=3116699095,2862677591&fm=253&fmt=auto&app=120&f=JPEG?w=890&h=500'
				}],
				stockList: [{
					id: 1,
					title: "淄博",
					number: 44645
				}, {
					id: 2,
					title: "西安",
					number: 154
				}, {
					id: 3,
					title: "成都",
					number: 7575
				}, {
					id: 4,
					title: "山东",
					number: 75542
				}, {
					id: 5,
					title: "东北",
					number: 758
				}, {
					id: 6,
					title: "淄博",
					number: 458
				}, {
					id: 7,
					title: "淄博",
					number: 8678
				}, {
					id: 1,
					title: "淄博",
					number: 44645
				}, {
					id: 2,
					title: "西安",
					number: 154
				}, {
					id: 3,
					title: "成都",
					number: 7575
				}, {
					id: 4,
					title: "山东",
					number: 75542
				}, {
					id: 5,
					title: "东北",
					number: 758
				}, {
					id: 6,
					title: "淄博",
					number: 458
				}, {
					id: 7,
					title: "淄博",
					number: 8678
				}],
				slide: [1, 2, 3, 4, 5], //定义一个小数组用来区分
				headers: {
					token: localStorage.getItem("token"),
				},
				fileList: [],
				dialogImageUrl: "",
				dialogVisible: false,
				disabled: false,
				ruleForm: {
					id: "",
					choose: '1',
					reason: '',
					productId: "",
					name: "",
					model: "",
					ywykNo: "",
					marketNo: "",
					series: "",
					suitableModel: "",
					spec: "",
					supplier: "",
					production: "",
					workingMileage: "",
					priceCost: "",
					rateInner: "",
					priceInner: "",
					rateStore: "",
					priceStore: "",
					rateStoreTax: "",
					priceStoreTax: "",
					rateFleet: "",
					priceFleet: "",
					rateFleetTax: "",
					priceFleetTax: "",
					rateDiscountFleet: "",
					priceFleetTaxDiscount: "",
					rateEndUser: "",
					priceEndUser: "",
					rateEndUserTax: "",
					priceEndUserTax: "",
					ratePartner: "",
					pricePartner: "",
					ratePartnerTax: "",
					pricePartnerTax: "",
					rateAutoCity: "",
					priceAutoCity: "",
					rateAutoCityTax: "",
					priceAutoCityTax: "",
					imgs: [],
				},
				originObjStr: "",
				rules: {
					name: [{
						required: true,
						message: "请输入产品名称",
						trigger: "blur",
					}, ],
					model: [{
						required: true,
						message: "请输入型号",
						trigger: "blur",
					}, ],
					choose: [{
						required: true,
						message: '请选择是否通过',
						trigger: 'change'
					}],
					reason: [{
						required: true,
						message: '请填写不通过原因',
						trigger: 'blur'
					}]
				},
				keys: [{
						key: "brand",
						name: "品牌",
					},
					{
						key: "name",
						name: "产品名称",
					},
					{
						key: "model",
						name: "型号",
					},
					{
						key: "ywykNo",
						name: "玉韦耶克号",
					},
					{
						key: "marketNo",
						name: "市场零件号",
					},
					{
						key: "series",
						name: "系列",
					},
					{
						key: "suitableModel",
						name: "适配机型",
					},
					{
						key: "spec",
						name: "产品规格备注",
					},
					{
						key: "supplier",
						name: "供应商",
					},
					{
						key: "production",
						name: "OEM或外采",
					},
					{
						key: "workingMileage",
						name: "保有公里数",
					},
					{
						key: "sizes",
						name: "产品详细尺寸",
					},
					{
						key: "imgs",
						name: "产品图片",
					},
					{
						key: "priceCost",
						name: "进价",
					},
					{
						key: "rateInner",
						name: "内部加价率",
					},
					{
						key: "priceInnter",
						name: "内部价格",
					},
					{
						key: "rateStore",
						name: "门店加价率",
					},
					{
						key: "priceStore",
						name: "门店不含税价格",
					},
					{
						key: "rateFleet",
						name: "车队不含税加价率",
					},
					{
						key: "priceFleet",
						name: "车队不含税价",
					},
					{
						key: "rateFleetTax",
						name: "车队含税加价率",
					},
					{
						key: "priceFleetTax",
						name: "车队含税价",
					},
					{
						key: "rateDiscountFleet",
						name: "车队优惠点数",
					},
					{
						key: "priceFleetTaxDiscount",
						name: "车队下浮的含税价",
					},
					{
						key: "rateEndUser",
						name: "终端不含税加价率",
					},
					{
						key: "priceEndUser",
						name: "终端不含税价",
					},
					{
						key: "ratePartner",
						name: "合伙人加价率",
					},
					{
						key: "pricePartner",
						name: "合伙人提货价",
					},
					{
						key: "rateAutoCity",
						name: "汽配城加价率",
					},
					{
						key: "priceAutoCity",
						name: "汽配城价",
					},
				],
				categoryList: [],
				adapters: []
			};
		},
		created() {
			if (this.$route.query.id) {
				this.ruleForm.productId = this.$route.query.id;
				this.getProduct();
			}
			this.getCategory()
		},
		mounted() {
			if (this.ruleForm.imgs.filter(e => e.major == 1).length > 0) {
				this.datuImgUrl = this.ruleForm.imgs.filter(e => e.major == 1)[0].img
			}
		},
		methods: {
			getMaterialName(id) {
				let arr = ['', '金属', '塑料', '橡胶', '玻璃', '纺织品', '润滑油液', '石棉纸板制品', '其他']
				if (id == 100) {
					id = arr.length - 1
				}
				return arr[id]
			},
			chooseChange(val) {
				if (val == 1) {
					this.reasonShow = true
				} else {
					this.reasonShow = false
				}
			},
			// carouselChange(index) {
			// 	this.xiaotuIndex = index
			// },
			// xiaotuImg(index) {
			// 	this.xiaotuIndex = index
			// 	this.datuImgUrl = this.ruleForm.imgs.filter(e => e.major == 1)[index].img
			// },
			power(p) {
				return power(p);
			},
			// handleRemove(file) {
			//     console.log(file);
			// },
			// handlePictureCardPreview(file) {
			//     this.dialogImageUrl = file.url;
			//     this.dialogVisible = true;
			// },
			rateDiv100(item) {
				commFunc.rateDiv100(item)
			},
			rateMul100(item) {
				commFunc.rateMul100(item)
			},
			getCategory() {
				http.fetchGet('/api/admin/category/list').then(res => {
					if (res.data.code == 0) {
						this.categoryList = res.data.data
					}
				})
			},
			getProduct() {
				http.fetchGet("/api/admin/product", {
					id: this.ruleForm.productId,
				}).then((res) => {
					if (res.data.code == 0) {
						console.log(res)
						this.ruleForm = res.data.data;
						this.ruleForm.productId = this.ruleForm.id;
						this.rateMul100(this.ruleForm);
						this.originObjStr = JSON.stringify(this.ruleForm);
						this.ruleForm.imgs.forEach(e => {
							e.img += '!watermark'
						})
						this.ruleForm.imgs.filter(e => e.major == 1).forEach((item) => {
							this.srcList.push(item.img)
						})
						this.ruleForm.imgs.filter(e => e.major != 1).forEach((item) => {
							this.otherSrcList.push(item.img)
						})

						for (var i = 0; i < this.ruleForm.imgs.length; i++) {
							this.fileList = this.fileList.concat([{
								url: this.ruleForm.imgs[i].img,
							}, ]);
						}
						if (this.ruleForm.imgs.filter(e => e.major == 1).length > 0) {
							this.datuImgUrl = this.ruleForm.imgs.filter(e => e.major == 1)[0].img
						}
						String.prototype.trimAllBlank = function() {
							return this.replace(/\s+/g, "");
						}
						this.adapters = []
						this.ruleForm.adapters.forEach((item) => {
							if (item) {
								let strain = item.split("-")[0]
								let name = item.split("-")[1]
								if (!this.adapters.find(e => e.strain.trimAllBlank() == strain
										.trimAllBlank() && e.name.trimAllBlank() == name.trimAllBlank())) {
									this.adapters.push({
										strain: strain,
										name: name
									})
								}
							}
						})
						this.ruleForm.adapters.splice(0, 1)
					}
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.ruleForm.productId) {
							if (power("price-edit") && power("verify-edit")) {
								this.saveProduct();
								return;
							}
							if (
								this.originObjStr == JSON.stringify(this.ruleForm)
							) {
								this.$message.info("未修改任何项，无需保存");
								return;
							} else {
								//保存修改
								let modify = {};
								modify.id = "";
								modify.productId = this.ruleForm.productId;
								modify.name = this.ruleForm.name;
								modify.model = this.ruleForm.model;
								modify.suitableModel = this.ruleForm.suitableModel;
								modify.priceEndUser = this.ruleForm.priceEndUser;
								let details = [];
								let orign = JSON.parse(this.originObjStr);
								this.keys.forEach((item) => {
									if (item.key == "imgs") {
										let oriImgs = orign.imgs.map((v) => {
											return v.img;
										});
										let imgs = this.ruleForm.imgs.map((v) => {
											return v.img;
										});
										if (oriImgs.join(",") != imgs.join(",")) {
											details.push({
												name: item.name,
												colName: item.key,
												beforeValue: oriImgs.join(","),
												afterValue: imgs.join(","),
											});
										}
									} else if (
										orign[item.key] != this.ruleForm[item.key]
									) {
										details.push({
											name: item.name,
											colName: item.key,
											beforeValue: orign[item.key],
											afterValue: this.ruleForm[item.key],
										});
									}
								});
								modify.details = details;
								http.fetchPost(
									"/api/admin/product/modify",
									modify
								).then((res) => {
									if (res.data.code == 0) {
										this.$message.success(
											"修改提交成功，请等待管理员审核"
										);
										this.$router.push("/");
									} else {
										this.$message.error(res.data.desc);
									}
								});
							}
						} else {
							this.saveProduct();
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			saveProduct() {
				this.rateDiv100(this.ruleForm);
				//保存
				http.fetchPost("/api/admin/product", this.ruleForm).then((res) => {
					if (res.data.code == 0) {
						this.$message.success("保存成功");
						this.$router.push("/");
					} else {
						this.$message.error(res.data.desc);
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			priceChange() {
				this.$nextTick(() => {
					commFunc.priceCalc(this.ruleForm)
					this.$forceUpdate();
				});
			},
			beforeAvatarUpload(file) {
				const isJPG =
					file.type === "image/jpeg" || file.type === "image/png";
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error("上传图片只能是 PNG 或 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			handlePictureCardSuccess(res, file, fileList) {
				this.updateImages(fileList);
			},
			handleRemove(file, fileList) {
				this.updateImages(fileList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			updateImages(fileList) {
				this.ruleForm.imgs = [];
				console.log(fileList);
				for (var i = 0; i < fileList.length; i++) {
					if (fileList[i].response) {
						this.ruleForm.imgs = this.ruleForm.imgs.concat([{
							img: fileList[i].response.data.url,
						}, ]);
					} else {
						this.ruleForm.imgs = this.ruleForm.imgs.concat([{
							img: fileList[i].url,
						}, ]);
					}
					console.log(this.ruleForm.imgs);
				}
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/spares-view.scss';
</style>
